import {css, html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {MessageType} from '../enums/message-type';

@customElement('chat-message')
export class ChatMessage extends LitElement {
  static override styles = css`
    .overlay {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #e7e7e7;
    }

    .container {
      display: flex;
      gap: 1rem;
      max-width: 694px;
      width: 694px;
      padding: 1.5rem 0;
    }

    .icon-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
    }

    .message-container {
      padding: 0.5rem 1rem;
      flex-grow: 1;
      border-radius: 0.5rem;
      word-wrap: break-word;
      min-height: 26px;
    }

    .message {
      line-height: 1.6rem;
      letter-spacing: 0.1px;
    }

    .user-message .message-container {
      background-color: #e9e9e6;
      color: #4c4a4f;
    }

    .bot-message .message-container {
      background-color: #ffffff;
      color: #4c4a4f;
    }

    .user-message .icon-container {
      order: -1;
    }

    .bot-message .icon-container {
      order: 1;
    }

    .user-icon,
    .bot-icon {
      width: 42px;
      height: 42px;
    }

    .thumbs-buttons {
      display: flex;
      gap: 8px;
      height: 36px;
    }

    .thumb-icon {
      width: 17px;
      height: 17px;
    }

    .round-btn {
      background-color: transparent;
      border: 1px solid #efefef;
      border-radius: 8px;
      outline: none;
      padding: 0.5rem;
      cursor: pointer;
    }

    .round-btn:hover {
      background-color: #e9e9e9;
    }

    .round-btn:active {
      background-color: var(--orange-300);
    }

    .round-btn:active .thumb-icon img {
      filter: brightness(0) invert(1);
    }

    .round-btn.selected {
      background-color: var(--orange-300);
    }

    .round-btn.selected .thumb-icon img {
      filter: brightness(0) invert(1);
    }

    .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      min-height: 36px;
      gap: 8px;
      padding-bottom: 1rem;
    }

    .dot-pulse {
      color: var(--orange-300);
      margin-top: 0.5rem;
      margin-left: 1rem;
    }

    .dot-pulse::before,
    .dot-pulse::after {
      color: var(--orange-300);
    }
  `;

  @property({type: String}) responseId?: string;
  @property({reflect: true}) messageType: MessageType = 'user';
  @property({type: String}) nickname?: string;
  @property() text = '';
  @property({type: Boolean}) displayFeedback = false;
  @property({type: String}) feedbackLabel?: string | null;
  @property({type: Array}) urls: string[] = [];

  private static _userIconUrl = '/static/images/user-icon-black.svg';
  private static _botIconUrl = '/static/images/ai-assistant.svg';
  private static _thumbsUpIconUrl = '/static/images/thumbs-up.svg';
  private static _thumbsDownIconUrl = '/static/images/thumbs-down.svg';

  private get iconUrl(): string {
    return this.messageType === 'user' ? ChatMessage._userIconUrl : ChatMessage._botIconUrl;
  }

  override render() {
    const isPulsingDots = this.text === 'loading...';

    return html`
      <link rel="stylesheet" href="/node_modules/three-dots/dist/three-dots.min.css" />
      <div class="overlay">
        <div class="container ${this.messageType}-message">
          <div class="icon-container">
            <div class="bot-icon">
              <img
                class="${this.messageType === 'user' ? 'user-icon' : 'bot-icon'}"
                src="${this.iconUrl}"
                alt="${this.messageType === 'user' ? 'User' : 'Bot'} Icon"
              />
            </div>
          </div>
          <div class="message-container">
            <div class="nickname">${this.nickname}</div>
            <div class="message">
              ${isPulsingDots ? html` <div class="dot-pulse"></div>` : this.text}
            </div>
          </div>
        </div>
        ${this.displayFeedback
          ? html` <div class="buttons-container">
              <references-button .urls="${this.urls}"></references-button>
              <copy-button .value="${this.text}"></copy-button>
              ${this.renderFeedbackButtons()}
            </div>`
          : ''}
      </div>
    `;
  }

  private renderFeedbackButtons() {
    return html`
      <div class="thumbs-buttons">
        <button
          class="round-btn thumbs-up ${this.feedbackLabel === 'positive' ? 'selected' : ''}"
          @click="${() => this.sendFeedback('positive')}"
          aria-pressed="${this.feedbackLabel === 'positive' ? 'true' : 'false'}"
        >
          <div class="thumb-icon">
            <img src="${ChatMessage._thumbsUpIconUrl}" alt="Thumbs Up" />
          </div>
        </button>
        <button
          class="round-btn thumbs-down ${this.feedbackLabel === 'negative' ? 'selected' : ''}"
          @click="${() => this.sendFeedback('negative')}"
          aria-pressed="${this.feedbackLabel === 'negative' ? 'true' : 'false'}"
        >
          <div class="thumb-icon">
            <img src="${ChatMessage._thumbsDownIconUrl}" alt="Thumbs Down" />
          </div>
        </button>
      </div>
    `;
  }

  private sendFeedback(feedback: 'positive' | 'negative') {
    this.feedbackLabel = feedback;
    this.dispatchEvent(
      new CustomEvent('send-feedback', {
        detail: {
          responseId: this.responseId,
          feedbackLabel: feedback,
        },
      })
    );
    this.requestUpdate();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'chat-message': ChatMessage;
  }
}
